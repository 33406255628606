/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 table.gltf 
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Table(props) {
  const { nodes, materials } = useGLTF("/table.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane010_Plane017.geometry}
        material={materials.Coffee_Table}
        position={[-3.349, -0.455, -4.138]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.745}
      />
      <mesh
        geometry={nodes.Plane011_Plane018.geometry}
        material={materials.Coffee_Table}
        position={[-3.349, -0.455, -4.138]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.745}
      />
      <mesh
        geometry={nodes.Plane012_Plane019.geometry}
        material={materials.Coffee_Table}
        position={[-3.349, -0.455, -4.138]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.745}
      />
    </group>
  );
}

useGLTF.preload("/table.gltf");
