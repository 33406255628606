/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 newGenieXHoodie.gltf 
*/

import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useTexture, Decal } from "@react-three/drei";
import { BoxHelper, Box3, Vector3, AxesHelper } from "three";
import { useFrame } from "@react-three/fiber";
import actOfCreationLogo from "../assets/actofcreationFamilyFriendsLogo.svg";
import actOfCreationLogoWhite from "../assets/actofcreationlogowhite.svg";

export function NewHoodie({ onClick, hoodieImage, genieXLogo, ...props }) {
  console.log("Rendering NewHoodie component");
  console.log("hoodieImage prop:", hoodieImage);
  // console.log("majorTest", hoodieImage);
  const group = useRef();
  const { nodes, materials } = useGLTF("/newGenieXHoodie.gltf");

  const [texture, setTexture] = useState(null);

  const textureScaleX = 0.88; // Repeat twice along X
  const textureScaleY = 0.88; // Repeat twice along Y
  const textureOffsetX = -1.0; // Shift half way along X
  const textureOffsetY = -0;
  const [rotation, setRotation] = useState(0);
  const [startAnimation, setStartAnimation] = useState(false);
  // console.log("majorTest", hoodieImage);

  // const [logoTexture] = useTexture([genieXLogo]);
  const [logoTexture] = useTexture([actOfCreationLogo]);
  const [logoTextureWhite] = useTexture([actOfCreationLogoWhite]);
  console.log("majorTest", hoodieImage);

  console.log(logoTexture);
  // const blackMaterial = new THREE.MeshBasicMaterial({ color: "black" }); // Define black material

  // useEffect(() => {
  //   console.log("step 1");
  //   if (hoodieImage) {
  //     console.log("step 2");
  //     const image = new Image();
  //     image.onload = () => {
  //       const texture = new THREE.Texture(image);
  //       texture.flipY = false;
  //       // ...additional texture configuration...
  //       texture.needsUpdate = true;
  //       setTexture(texture);
  //     };
  //     image.onerror = (error) => {
  //       console.error("Error loading image:", error);
  //     };
  //     image.src = hoodieImage;
  //   }
  //   console.log("step 3");
  // }, [hoodieImage]);

  console.log("hoodie image", hoodieImage);

  useEffect(() => {
    console.log("hoodie image", hoodieImage);

    if (!hoodieImage) {
      console.log("the hoodie image is undefined");
      // Handle the case when hoodieImage is undefined
      return;
    }

    console.log("the function got this far");
    const loader = new THREE.TextureLoader();
    loader.load(
      hoodieImage,
      (loadedTexture) => {
        loadedTexture.flipY = false;
        loadedTexture.minFilter = THREE.LinearFilter;
        loadedTexture.magFilter = THREE.LinearFilter;
        loadedTexture.encoding = THREE.sRGBEncoding;
        loadedTexture.needsUpdate = true;
        loadedTexture.wrapS = loadedTexture.wrapT = THREE.RepeatWrapping;
        loadedTexture.offset.set(textureOffsetX, textureOffsetY);

        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error(
          "An error occurred while loading the texture.",
          error.message
        );
      }
    );
  }, [hoodieImage]);

  const blackMaterial = new THREE.MeshStandardMaterial({ color: "#000000" });

  const decalPosition = new Vector3(0.5, 1, 0.1); // Adjust x, y, and z accordingly
  const decalRotation = new Vector3(0, 0, 0); // May need to rotate the decal
  const decalScale = new Vector3(1, 1, 1); // Scale down the decal to fit the hoodie

  useEffect(() => {
    // Set the initial rotation here to face the front
    // Adjust these values if your model's front is oriented differently
    if (group.current) {
      group.current.rotation.y = 0;
    }
  }, []);

  useEffect(() => {
    // Set a timeout to start the animation
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 1000); // Delay of 1 second

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);

  // Rotate the hoodie every frame after the timeout
  useFrame(() => {
    if (group.current && rotation < Math.PI && startAnimation) {
      const step = 0.01; // Adjust the speed of the rotation here
      group.current.rotation.y += step;
      setRotation(rotation + step);
    }
  });

  return (
    <group
      {...props}
      dispose={null}
      ref={group}
      rotation={[0, rotation, 0]}
      onClick={onClick}
    >
      <group scale={[10, 10, 10]}>
        <mesh
          geometry={nodes.Pattern2D_1116363_Node001.geometry}
          material={materials.body}
          // material={blackMaterial}
        >
          {/* <meshBasicMaterial
            attach="material"
            color={materials.body.color}
            wireframe
          /> */}
          <Decal
            position={[0.0, 0.16, 0.1]}
            rotation={[0, 0, 0]}
            scale={[0.1 * 1.5, 0.02 * 1.5, 0.1 * 1.5]}
            map={logoTexture}
            // map={logoTextureWhite}
            map-anisotropy={16}
            transparent={true}
            alphaTest={0.5}
          />
        </mesh>
        <mesh
          geometry={nodes.Pattern2D_1116363_Node002.geometry}
          material={materials.body}
          // material={blackMaterial}
          position={[0, -0.004, 0]}
          scale={1.002}
        />
        <mesh
          geometry={nodes.Pattern2D_1116363_Node003.geometry}
          // material={blackMaterial}
          position={[0, 0, 0.001]}
        >
          {texture && (
            <meshStandardMaterial
              attach="material"
              map={texture}
              transparent={true}
              // alphaMap={texture}
            />
          )}
        </mesh>
        <mesh
          geometry={nodes.Pattern2D_1116363_Node004.geometry}
          material={materials.body}
          // material={blackMaterial}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/newGenieXHoodie.gltf");
